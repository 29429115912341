import { ControlBaseProps, ControlData } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import * as Enumerable from 'linq';
import * as React from 'react';
import { IControl } from '../../../Core/Domain/Models/Controls';
import { Document, IDocument } from '../../../Core/Domain/ViewModels/Document';
import { FileList } from '../../Esign/FileList/FileList';
import { PdfViewManager } from './PdfViewBase';
import { Viewer } from './Viewer';
import { getConditionalControlsList, getConditionalControlsRules, getRemovedConditonalDataDocuments, getUpdatedConditionalControlsList } from '../ConditionalControls/ConditionalControls';
import { ISignatureControlModel } from '../../../Core/Domain/ViewModels/DocumentViewModel';


export interface PdfViewProps {
    documents: IDocument[];
    onSigningCompleted(): void;
    downloadInitialDocuments(): void;
    AssignToDelegateeSigner: boolean;
    disableNextButton: (value: boolean) => void;
}

export interface focusControlId {
    lastFocusControlID: string;
    lastConditionalControlID: string;
}

export interface PdfViewState {
    documents: IDocument[];
    document: IDocument;
    startNavigationOnDocumentLoad: boolean;
    progress: number;
    conditionalControlsList: ISignatureControlModel[];
    isConditionalControlListUpdated: boolean;
    controlsUpdatedInDocument:boolean;
    focusControlId: focusControlId;
}


export class PdfView extends React.Component<PdfViewProps, PdfViewState> {

    private _viewer: any;

    constructor(props: any) {

        super(props);

        this.state = {
            documents: [],
            startNavigationOnDocumentLoad: false,
            document: Document.createNullObject(),
            progress: 0,
            conditionalControlsList: [],
            isConditionalControlListUpdated: false,
            controlsUpdatedInDocument:false,
            focusControlId: {lastFocusControlID: "", lastConditionalControlID: ""}
        }
    }

    static getDerivedStateFromProps(nextProps: PdfViewProps, nextState: PdfViewState) {
        if (nextProps.documents.length > 0 && nextState.document) {

            let document: IDocument = Document.createNullObject();

            if (nextState.document.id > 0) {
                document = nextState.document;
            }
            else {

                document = nextProps.documents.filter(x => x.disabled == false
                    && x.pages.some(y => y.controls.length > 0))[0];

                if (!document) {
                    document = nextProps.documents.filter(x => x.disabled == false)[0];
                }
            }

            const conditionalControlsList = nextState.isConditionalControlListUpdated ? nextState.conditionalControlsList : getConditionalControlsList(nextProps.documents);
            
            return {
                documents: nextProps.documents,
                document: document,
                progress: PdfViewManager.getControlValueUpdatedPercentage(getRemovedConditonalDataDocuments(nextProps.documents,conditionalControlsList)),
                conditionalControlsList: conditionalControlsList
            };

        }
        else return { documents: nextProps.documents, document: Document.createNullObject() };
    }

    componentDidUpdate(prevProps: PdfViewProps, prevState: PdfViewState) {
        if (this.state.progress >= 100 && prevState.progress != this.state.progress) {
            this.props.onSigningCompleted();
        } 
    }

    shouldComponentUpdate(nextProps: PdfViewProps, nextState: PdfViewState) {  
        if (nextProps.documents.length > 0 && this.props.documents.length !== nextProps.documents.length
            || (this.state.document.id > 0 && this.state.document.id !== nextState.document.id) || this.state.progress !== nextState.progress || this.state.isConditionalControlListUpdated ) {
            return true;
        }
        return false;
    }

    handleFileClick = (document: IDocument) => {
        this.setState({ document: document, startNavigationOnDocumentLoad: true });
    }

    handleNavigationFinish = () => {
        this.setDocument();
    }

    public getTempDocumentsData = (): IDocument[] => {
        return getRemovedConditonalDataDocuments(this.state.documents, this.state.conditionalControlsList);	
    }

    public getFinalDocumentsData = (): IDocument[] => {
        return getRemovedConditonalDataDocuments(this.state.documents, this.state.conditionalControlsList, true);	
    }

    public showValidationMessage = (document: IDocument, page: number, control: IControl) => {

        this.setState({ document: document }, () => {
            this._viewer.showValidationMessage(page, control);
        });

    }

    private setDocument = () => {

        if (this.state.document.id > 0) {

            const documents = this.state.documents.filter(x => x.disabled == false
                && x.pages.some(y => y.controls.length > 0));

            // set next document using index
            const nextDocumentIndex = documents.indexOf(this.state.document) + 1;

            const nextDocument: IDocument | undefined = Enumerable.from(documents).elementAtOrDefault(nextDocumentIndex);

            if (nextDocument == undefined) {
                return;
            }

            if (documents.length == nextDocumentIndex + 1) {
                this.setLastDocument(nextDocument);
            }
            else {
                this.setNextDocument(nextDocument);
            }
        }
        else {
            this.setFirstDocument();
        }
    }

    private setFirstDocument = () => {
        this.setState({ document: this.state.documents[0] });
    }

    private setNextDocument = (nextDocument: IDocument) => {

        this.setState({
            document: nextDocument,
            startNavigationOnDocumentLoad: true
        });
    }

    private setLastDocument = (nextDocument: IDocument) => {
        this.setState({ document: nextDocument, startNavigationOnDocumentLoad: true });
    }

    private isLastDocument = (document: IDocument) => {

        const documents = this.state.documents.filter(x => x.disabled == false
            && x.pages.some(y => y.controls.length > 0));

        // set next document using index
        const nextDocumentIndex = documents.indexOf(document) + 1;

        const nextDocument: IDocument | undefined = Enumerable.from(documents).elementAtOrDefault(nextDocumentIndex);

        if (nextDocument == undefined) {
            return true
        }

        return false;
    }

    private updateControlData = (controlData: ControlData, controlProps: ControlBaseProps) => {
        const { documents } = this.state;
        PdfViewManager.updateControlData(documents, controlData, controlProps);

        const UpdatedConditionalControlsList = getUpdatedConditionalControlsList(this.state.conditionalControlsList, getConditionalControlsRules(documents), controlData, controlProps);

        this.setState({ conditionalControlsList: UpdatedConditionalControlsList, isConditionalControlListUpdated: true });
        
        if( controlProps.data["controlType"] == 11 ||  controlProps.data["controlType"] == 9){
            const isConditionalControl =  this.getDocumentConditionalControls(this.state.document).findIndex((x) => "document-control-"+x.controlGuid == controlProps.id);

            if(isConditionalControl != -1){
                if(this._viewer && this._viewer._controlLayer){
                    const lastFocusControlID= this._viewer._controlLayer._navigation && this._viewer._controlLayer._navigation.currentStep && this._viewer._controlLayer._navigation.currentStep.id;
                    this._viewer._controlLayer.removeAllNavigationSteps && this._viewer._controlLayer.removeAllNavigationSteps();
                    this.setState({controlsUpdatedInDocument:true, focusControlId: {lastFocusControlID: lastFocusControlID, lastConditionalControlID: controlProps.id}});
                }
            }
        }

        const completedPercentage = PdfViewManager.getControlValueUpdatedPercentage(getRemovedConditonalDataDocuments(documents, UpdatedConditionalControlsList));
        completedPercentage >= 0 && this.setState({ progress: completedPercentage }, () => {
            completedPercentage >= 100 ? this.props.onSigningCompleted() : this.props.disableNextButton(true);
        });
        this.forceUpdate();
    }

    private getDocumentConditionalControls = (document: IDocument) =>{
        let documentConditionalControls = [];
        
        if(document.conditionalControlsRules.length > 0){
            documentConditionalControls = document.conditionalControlsRules.map((control) => control.conditionalControl);
        }

        return documentConditionalControls;
    }


    public render() {

        const { documents, document } = this.state;

        const finishTarget: string = this.isLastDocument(document) ? "btnContinue" : "";

        return (<div className="pdf-preview-container">
            <Viewer ref={(ref) => this._viewer = ref}
                pages={document.pages}
                url={document.url}
                conditionalControlsList={this.state.conditionalControlsList}
                progress={this.state.progress}
                fileList={<FileList selectedDocumentId={document ? document.id : 0} documents={documents} onFileClick={this.handleFileClick} />}
                startNavigationOnDocumentLoad={this.state.startNavigationOnDocumentLoad}
                finishTarget={finishTarget}
                onNavigationFinish={this.handleNavigationFinish}
                onAddControlData={this.updateControlData}
                downloadInitialDocuments={this.props.downloadInitialDocuments}
                AssignToDelegateeSigner={this.props.AssignToDelegateeSigner}
                controlsUpdatedInDocument={this.state.controlsUpdatedInDocument}
                controlsUpdatedInDocumentStateChange={(value:boolean) => this.setState({controlsUpdatedInDocument:value})}
                focusControlId = {this.state.focusControlId}
            />
        </div>);

    }
}
