import PdfViewer from 'awesome-pdf-viewer';
import ControlBase, { ControlData, LabelData, SignatureData, TextBoxData, CheckBoxData, RadioButton, RadioButtonData, TextBoxTypes } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import LabelControl from 'awesome-pdf-viewer/dist/Controls/LabelControl';
import SignatureControl, { SignatureControlProps } from 'awesome-pdf-viewer/dist/Controls/SignatureControl';
import TextBoxControl, { TextBoxControlProps } from 'awesome-pdf-viewer/dist/Controls/TextBoxControl';
import CheckBoxControl, { CheckBoxControlProps } from 'awesome-pdf-viewer/dist/Controls/CheckBox/CheckBoxControl';
import { RadioButtonControl, RadioButtonControlProps } from 'awesome-pdf-viewer/dist/Controls/RadioButtonControl/RadioButtonControl';
import ControlLayer from 'awesome-pdf-viewer/dist/Layers/ControlLayer';
import { ControlBaseProps } from 'awesome-pdf-viewer/dist/Layers/ControlLayerBase';
import Header from 'awesome-pdf-viewer/dist/layout/Header';
import LeftPanel from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import Main from 'awesome-pdf-viewer/dist/layout/Main';
import {PdfSource} from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import Toolbar from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import * as Controls from '../../..//Core/Domain/Models/Controls';
import { IPage } from '../../../Core/Domain/ViewModels/Document';
import * as React from 'react';
import Progress from '../Progress/Progress';
import { ControlHelper } from '../../Helper/ControlHelper';
import { Pagination } from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import OtherOptions from 'awesome-pdf-viewer/dist/toolbar/OtherOptions';
import { PageProperties, PageSize } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { ISignatureControlModel } from '../../../Core/Domain/ViewModels/DocumentViewModel';
import { isControlValid } from '../ConditionalControls/ConditionalControls';
import { focusControlId } from './PdfView';



export interface ViewProps {
    url: string;
    pages: IPage[];
    fileList: any;
    startNavigationOnDocumentLoad?: boolean;
    finishTarget?: string;
    progress: number;
    onNavigationFinish(): void;
    onAddControlData(controlData: ControlData, controlProps: ControlBaseProps): void;
    downloadInitialDocuments(): void;
    AssignToDelegateeSigner: boolean;
    controlsUpdatedInDocument:boolean;
    conditionalControlsList: ISignatureControlModel[];
    controlsUpdatedInDocumentStateChange: (value: boolean) => void;
    focusControlId? : focusControlId;
}


export interface ViewState {
    hideStartNavigationControl: boolean;
    signatureData: SignatureData | undefined;
    initialcontrlData: SignatureData | undefined;
    isDocumentLoaded: boolean;
}

const Radio_Button_Offset_Top: number = 7;

export class Viewer extends React.Component<ViewProps, ViewState> {

    private _viewPanel: any;
    private _controlLayer: any;
    private _controlList: any[] = [];
    private _toolbar: any;
    private _controlDisplayPanel: any;


    constructor(props: any) {
        super(props);

        this.state = {
            hideStartNavigationControl: false,
            signatureData: undefined,
            initialcontrlData: undefined,
            isDocumentLoaded: false
        }
    }

    componentDidMount() {
        this.setReferences();
    }

    setReferences() {
        this._toolbar.setViewerReference(this._viewPanel);
        this._viewPanel.setToolbarReference(this._toolbar);
        this._viewPanel.setControlsReference(this._controlList);
        this._viewPanel.setControlLayerReference(this._controlLayer);
        this._controlLayer.setViewerReference(this._viewPanel);
        this._controlLayer.setControlsReference(this._controlList);
        this._controlLayer.setcontrolDisplayPanelReference(this._controlDisplayPanel);
    }

    componentDidUpdate() {
        this.setReferences();
        this.setControlScale();
        this.checkNextButtonFocus(this);
    }

    private checkNextButtonFocus = (_self : any) => {
        // In this we are cheking if next pointer is pointing to the control which is not in the control list then we are setting the focus to the next control
        if(this.state.hideStartNavigationControl && this.state.isDocumentLoaded){
            if(_self._controlLayer && _self._controlLayer?._navigation ){
                if(_self._controlLayer._navigation?.steps && _self._controlLayer._navigation.steps?.length > 0){
                    if(_self._controlLayer._navigation?.currentStep && _self._controlLayer._navigation?.currentStep?.id ){
                        let isControlExist =_self._controlLayer._navigation.steps.findIndex((x) => x.id == _self._controlLayer._navigation?.currentStep?.id);
                        if(isControlExist == -1){
                            _self.setNextButtonFocus();
                        }

                    }
                }
            }
        }
    }

    private setNextButtonFocus = () => {
        let _self = this;
        let focusButtonId;

        if(_self._controlLayer && _self._controlLayer?._navigation ){

            if(this.state.hideStartNavigationControl){
                focusButtonId = _self.checkIsControlValidInControlList(_self);
                if(focusButtonId){
                    let currentNavigationStepId = _self._controlLayer._navigation.getCurrentStep()?.id;
                    let previousStepIdIndex = _self._controlLayer._navigation.steps.findIndex((x) => x.id == currentNavigationStepId);
                    if(previousStepIdIndex != -1 && _self._controlLayer._navigation.steps[previousStepIdIndex - 1 ]?.id == focusButtonId ){
                        let control = this._controlList.find(x => x && x.props.id == focusButtonId);
                        if(!control?.props?.isRequired){
                            _self._controlLayer._navigation.steps.splice(previousStepIdIndex-1, 1);
                            return;
                        }
                    }
                    if(_self._controlLayer._navigation.steps && _self._controlLayer._navigation.steps.length > 0){
                        let controlStep =_self._controlLayer._navigation.steps.filter((x) => x.id == focusButtonId)[0];
                        if(controlStep){
                            let key = _self._controlLayer._navigation.steps.findIndex((x) => x.id == controlStep.id);
                            if(_self._controlLayer._navigation?.currentStep && _self._controlLayer._navigation?.currentStep?.id ){
                                _self._controlLayer._navigation?.hide();
                            }
                            _self._controlLayer._navigation?.show && _self._controlLayer._navigation.show(key);
                        }
                    }
                }
                else{
                    if(_self._controlLayer._navigation.steps && _self._controlLayer._navigation.steps.length > 0){
                        if(_self.props.controlsUpdatedInDocument){
                            const findLastFocusControlIDIndex =  _self._controlLayer._navigation.steps.findIndex((x) => x.id == _self.props.focusControlId.lastFocusControlID)
                            if(findLastFocusControlIDIndex != -1){
                                _self._controlLayer._navigation?.show && _self._controlLayer._navigation.show(findLastFocusControlIDIndex);
                                _self._controlLayer._navigation?.next && _self._controlLayer._navigation.next();
                            }
                            else{
                                 const findLastFocusControlIDIndex =  _self._controlLayer._navigation.steps.findIndex((x) => x.id == _self.props.focusControlId.lastConditionalControlID)
                                _self._controlLayer._navigation?.show && _self._controlLayer._navigation.show(findLastFocusControlIDIndex);
                                _self.props.controlsUpdatedInDocument && _self.props.controlsUpdatedInDocumentStateChange(false);
                            }
                        }
                    }
                }
            }
        }

    }

    private onNavigationNext = () =>{
        let _self = this;
        this.setNextButtonFocus();
        _self.props.controlsUpdatedInDocument && _self.props.controlsUpdatedInDocumentStateChange(false);
    }

    private checkIsControlValidInControlList = (_self) => {
        if(this._controlList && this._controlList.length > 0){
            for (let index = 0; index < _self._controlList.length; index++) {
                if(_self._controlList[index]){
                    let isValid = isControlValid(_self._controlList[index]);
                    if(!isValid){
                        return _self._controlList[index].props.id;
                    }
                }
            }
        }

        return;
    }

    private onNavigationComplete = () => {
        let _self = this;
        if(this.checkIsControlValidInControlList(_self)){
            if(_self.props.controlsUpdatedInDocument){
                this.forceUpdate();
                this.setNextButtonFocus();
                _self.props.controlsUpdatedInDocumentStateChange(false);
            }
            else if(_self.props.progress >= 100){
                this.props.onNavigationFinish();
            }
            else{
                _self.props.progress < 100 && this.setState({ isDocumentLoaded: false });
                this.props.onNavigationFinish();
            }
        }   
        else{
            _self.props.progress < 100 && this.setState({ isDocumentLoaded: false });
            this.props.onNavigationFinish();
        }
    }


    private setControlScale = () => {
        let _self = this;
        let currentScale = _self._viewPanel &&  _self._viewPanel._viewer.getCurrentScale();
        let currentPageNumber = _self._viewPanel && _self._viewPanel._viewer.getCurrentPage();
        let pageProperties : PageProperties = PageProperties.create(currentPageNumber, currentScale);

        if(currentScale != 1){
            if(_self._controlList.length >0){
                _self._controlList.forEach((control: any) => {
                    if(control && control.state.scale != currentScale){
                        if(control?.props && control.props?.data && control.props.data?.controlType && control.props.data.controlType == 11){
                            const element = document.getElementById(control.props.id);
                            element && element.remove();
                        }
                        control && control.updateControlState &&  control.updateControlState(pageProperties);
                    }
                });
            }
        }
    }

    public showValidationMessage = (page: number, control: Controls.IControl) => {

        this._viewPanel.gotoPage(page);
        const tmpControl: any = this._controlList.find(x => x && x.props.id == "document-control-" + control.id);
        tmpControl && tmpControl.setValidationStatus(false);

    }

    handleViewModeChange = () => {
        if (this._toolbar &&
            this._toolbar.handleModeChange) {
            this._toolbar.handleModeChange();
        }
    }

    private startNavigation = () => {

        this.setState({ isDocumentLoaded: true }, () => {

            let _self = this;
            this.closeNavigation();

            // find the page it contains controls and navigate to that page

            if (_self.props.startNavigationOnDocumentLoad) {
                const page: IPage | undefined = this.props.pages.find((x: IPage) => x.controls.length > 0);
                page && _self._viewPanel.gotoPage(page.page);
            }

            // some delay to load the pdf pages with controls

            setTimeout(
                function () {
                    _self.props.startNavigationOnDocumentLoad && _self.state.hideStartNavigationControl && _self._controlLayer.startNavigation();
                    if (_self._viewPanel && _self._viewPanel._viewer && _self._viewPanel._viewer.getCurrentScale() != 1) {
                        _self._viewPanel && _self._viewPanel.zoomOut();
                        _self._viewPanel && _self._viewPanel.zoomIn();
                    }
                }.bind(this), 2000);
        });

    }

    private closeNavigation = () => {
        this._controlLayer.closeNavigation();
    }

    private setAllSignatureControlValueToSame = (signatureData: SignatureData, controlProps: SignatureControlProps) => {

        this.setState({ signatureData: signatureData }, () => {

            this._controlList.forEach(function (item) {

                if (item && item.props.data && item.props.data.controlType == 1 && item.isSigned()) { // signature control
                    item.setControlData({ name: signatureData.name, signature: signatureData.signature, signatureMode: signatureData.signatureMode });
                }

            });

            this.props.onAddControlData(signatureData, controlProps);
        });
    }

    private setAllInitailControlValueToSame = (initialcontrlData: SignatureData, controlProps: SignatureControlProps) => {

        this.setState({ initialcontrlData: initialcontrlData }, () => {

            this._controlList.forEach(function (item) {

                if (item && item.props.data && item.props.data.controlType == 2 && item.isSigned()) { // signature control
                    item.setControlData({ name: initialcontrlData.name, signature: initialcontrlData.signature, signatureMode: initialcontrlData.signatureMode });
                }

            });

            this.props.onAddControlData(initialcontrlData, controlProps);
        });
    }


    setSignature = (controlProps: any, controlDisable?: boolean) => {

        const { signatureData } = this.state;
        const control = this._controlList.find(x => x && x.props.id == controlProps.id);

        if (signatureData) {

            control && control.setControlData({ name: signatureData.name, signature: signatureData.signature, signatureMode: signatureData.signatureMode });
            this.props.onAddControlData(signatureData, controlProps);
        }
        else {
            if (controlDisable == null
                || (controlDisable && controlDisable !== true)
            ) {
                control && control.openSignatureModal();
            }
        }
    }

    setInitial = (controlProps: any, controlDisable?: boolean) => {

        const { initialcontrlData } = this.state;
        const control = this._controlList.find(x => x && x.props.id == controlProps.id);

        if (initialcontrlData) {

            control && control.setControlData({ name: initialcontrlData.name, signature: initialcontrlData.signature, signatureMode: initialcontrlData.signatureMode });
            this.props.onAddControlData(initialcontrlData, controlProps);
        }
        else {
            if (controlDisable == null
                || (controlDisable && controlDisable !== true)
            ) {
                control && control.openSignatureModal();
            }
        }
    }

    private setAllTextControlValueToSame = (textBoxData: TextBoxData, controlPops: TextBoxControlProps) => {
        
        let _self = this;
        this._controlList.forEach(function (item: any) {

            if (item && controlPops.id == item.props.id)
                return;

            if (item && item.props.data && item.props.data.controlType == controlPops.data.controlType) {
                item.setControlData(textBoxData);
                _self._controlLayer.validateControlData(item.props.id);
            }

        });

        this.props.onAddControlData(textBoxData, controlPops);
        this._controlLayer.validateControlData(controlPops.id);
    }

    private setAllCustomTextControlValue=(textBoxData: TextBoxData, controlPops: TextBoxControlProps)=>{
        this.props.onAddControlData(textBoxData, controlPops);
        this._controlLayer.validateControlData(controlPops.id);
    }

    private setAllCheckBoxControlValue = (checkBoxData: CheckBoxData, controlPops: CheckBoxControlProps) => {

        this._controlList.forEach(function (item: any) {
            if (item && item.props.data && item.props.data.controlType == controlPops.data.controlType && item.props.id == controlPops.id) {
                item.setControlData(checkBoxData);
            }
        });
        this.props.onAddControlData(checkBoxData, controlPops);
        this._controlLayer.validateControlData(controlPops.id);
    }

    private setAllRadioControlValue = (radioData: RadioButton[], controlPops: RadioButtonControlProps) => {
        
        this.props.onAddControlData(radioData, controlPops);
        this._controlLayer.validateControlData(controlPops.id);
    }


    private removeControl = (control: Controls.IControl) => {            
        this._controlLayer.removeControl("document-control-"+control.id);
    }

    private removingConditionalControlValidation = (conditionalControlsList : any) => {
        if(conditionalControlsList && conditionalControlsList.length > 0){
            conditionalControlsList.forEach((control) => {
                var element = document.getElementById("awesome-pdf-viewer-tooltip-document-control-" + control.controlGuid);
                if (element) {
                    element.remove(); // Removes the element from the DOM
                }
            });
        }
    }

    private getControlHeight = (control: Controls.IControl): number => {
        let height = 0;
        switch (true) {
            case control instanceof Controls.SignatureControl:
                height = (control.height != null && control.height != undefined && control.height > 0) ?
                    control.height : ControlHelper.defaultSignatureControlHeight;
                break;
            case control instanceof Controls.InitialControl:
                height = (control.height != null && control.height != undefined && control.height > 0) ?
                    control.height : ControlHelper.defaultInitialControlHeight;
                break;
            case control instanceof Controls.DateControl:
                height = (control.height != null && control.height != undefined && control.height > 0) ?
                    control.height : ControlHelper.defaultDateControlHeight;
                break;
            case control instanceof Controls.NameAndTitleControl:
                height = (control.height != null && control.height != undefined && control.height > 0) ?
                    control.height : ControlHelper.defaultNameAndTitleControlHeight;
                break;
            case control instanceof Controls.NameControl:
                height = (control.height != null && control.height != undefined && control.height > 0) ?
                    control.height : ControlHelper.defaultNameControlHeight;
                break;
            case control instanceof Controls.TitleControl:
                height = (control.height != null && control.height != undefined && control.height > 0) ?
                    control.height : ControlHelper.defaultTitleControlHeight;
                break;
            case control instanceof Controls.CompanyControl:
                height = (control.height != null && control.height != undefined && control.height > 0) ?
                    control.height : ControlHelper.defaultCompanyControlHeight;
                break;
            case control instanceof Controls.CustomTextControl:
                height = (control.height != null && control.height != undefined && control.height > 0) ?
                    control.height : ControlHelper.defaultCustomTextControlHeight;
                break;
            case control instanceof Controls.CheckBoxControl:
                height = (control.height != null && control.height != undefined && control.height > 0) ?
                    control.height : ControlHelper.defaultCheckBoxControlHeight;
                break;
            case control instanceof Controls.RadioControl:
                height = (control.height != null && control.height != undefined && control.height > 0) ?
                    control.height : ControlHelper.defaultCheckBoxControlHeight;
                break;
            case control instanceof Controls.PrintNameControl:
                height = (control.height != null && control.height != undefined && control.height > 0) ?
                    control.height : ControlHelper.defaultPrintNameControlHeight;
                break;
        }
        return height;
    }

    private getControlWidth = (control: Controls.IControl): number => {
        let width = 0;
        switch (true) {
            case control instanceof Controls.SignatureControl:
                width = (control.width != null && control.width != undefined && control.width > 0) ?
                    control.width -21 : ControlHelper.defaultSignatureControlWidth;
                break;
            case control instanceof Controls.InitialControl:
                width = (control.width != null && control.width != undefined && control.width > 0) ?
                    control.width -21 : ControlHelper.defaultInitialControlWidth;
                break;
            case control instanceof Controls.DateControl:
                width = (control.width != null && control.width != undefined && control.width > 0) ?
                    control.width : ControlHelper.defaultDateControlWidth;
                break;
            case control instanceof Controls.NameAndTitleControl:
                width = (control.width != null && control.width != undefined && control.width > 0) ?
                    control.width : ControlHelper.defaultNameAndTitleControlWidth;
                break;
            case control instanceof Controls.NameControl:
                width = (control.width != null && control.width != undefined && control.width > 0) ?
                    control.width : ControlHelper.defaultNameControlWidth;
                break;
            case control instanceof Controls.TitleControl:
                width = (control.width != null && control.width != undefined && control.width > 0) ?
                    control.width : ControlHelper.defaultTitleControlWidth;
                break;
            case control instanceof Controls.CompanyControl:
                width = (control.width != null && control.width != undefined && control.width > 0) ?
                    control.width : ControlHelper.defaultCompanyControlWidth;
                break;
            case control instanceof Controls.CustomTextControl:
                width = (control.width != null && control.width != undefined && control.width > 0) ?
                    control.width : ControlHelper.defaultCustomTextControlWidth;
                break;
            case control instanceof Controls.CheckBoxControl:
                width = (control.width != null && control.width != undefined && control.width > 0) ?
                    control.width : ControlHelper.defaultCheckBoxControlWidth;
                break;
            case control instanceof Controls.RadioControl:
                width = (control.width != null && control.width != undefined && control.width > 0) ?
                    control.width : ControlHelper.defaultCheckBoxControlWidth;
                break;
            case control instanceof Controls.PrintNameControl:
                width = (control.width != null && control.width != undefined && control.width > 0) ?
                    control.width : ControlHelper.defaultNamePrintNameWidth;
                break;
        }
        return width;
    }

    private createControls = () => {

        this._controlList = [];
        let controlCollection: any[] = [];
        let radioCount: number = 0;
        let _self = this;
        const { pages } = this.props;
        const { isDocumentLoaded } = this.state;
        let { conditionalControlsList } = this.props;
        
        if (!isDocumentLoaded)
            return this._controlList;

        pages.forEach(function (page: IPage) {

            const pageSize: PageSize = _self._viewPanel.getPageSize(page.page, 1);

            if (pageSize.height > 0) {

                for(const control of page.controls) {

                    let isControlConditional = conditionalControlsList.length > 0 ? conditionalControlsList.find(x => x.controlGuid == control.id) : false;

                    if (isControlConditional) {
                        _self.removeControl(control);
                        continue;
                    }
                    
                    const top = ControlBase.getPdfViewerControlTopPosition(pageSize.height, control.top);
                    const left = ControlBase.getPdfViewerControlLeftPosition(control.left);

                    if (control instanceof Controls.SignatureControl) {

                        const controlData: Controls.IControlData | undefined = control.data;
                        const signatureData: SignatureData | undefined = controlData && SignatureData.create(
                            (controlData as Controls.ISignatureData).name,
                            (controlData as Controls.ISignatureData).signature,
                            (controlData as Controls.ISignatureData).signatureMode);

                        controlCollection.push(<SignatureControl isRequired={control.required}
                            ref={(ref) => _self._controlList.push(ref)} page={page.page} id={"document-control-" + control.id}
                            key={"sig-" + control.id}
                            name={"Signature"}
                            helptext={control.tooltip} onAddSignature={_self.setAllSignatureControlValueToSame}
                            top={top} left={left}
                            width={_self.getControlWidth(control)}
                            height={_self.getControlHeight(control)}
                            data={{ controlType: 1, controlId: control.id }}
                            signatureData={signatureData} disabled={true} onClick={(controlProps: any) => { _self.setSignature(controlProps, control.disabled) }} />);
                    }
                    else if (control instanceof Controls.InitialControl) {

                        const controlData: Controls.IControlData | undefined = control.data;
                        const initialControlData: SignatureData | undefined = controlData && SignatureData.create(
                            (controlData as Controls.ISignatureData).name,
                            (controlData as Controls.ISignatureData).signature,
                            (controlData as Controls.ISignatureData).signatureMode);

                        controlCollection.push(<SignatureControl isRequired={control.required}
                            ref={(ref) => _self._controlList.push(ref)} page={page.page} id={"document-control-" + control.id}
                            key={"initial-" + control.id}
                            name={"Initial"}
                            helptext={control.tooltip} onAddSignature={_self.setAllInitailControlValueToSame}
                            top={top} left={left}
                            width={_self.getControlWidth(control)}
                            height={_self.getControlHeight(control)}
                            data={{ controlType: 2, controlId: control.id }}
                            signatureData={initialControlData} disabled={true} onClick={(controlProps: any) => { _self.setInitial(controlProps, control.disabled) }} />);
                    }
                    else if (control instanceof Controls.DateControl) {

                        const controlData: Controls.IControlData | undefined = control.data;
                        const labelData: LabelData | undefined = controlData && LabelData.create((controlData as Controls.ITextData).text);

                        controlCollection.push(<LabelControl skipNavigation={true} ref={(ref) => _self._controlList.push(ref)} page={page.page}
                            id={"document-control-" + control.id}
                            key={"date-" + control.id}
                            name={"Current Date"} value={labelData || LabelData.createNullObject()}
                            helptext={control.tooltip} data={{ controlType: 3, controlId: control.id }}
                            top={top} left={left}
                            width={_self.getControlWidth(control)}
                            height={_self.getControlHeight(control)}
                            disabled={control.disabled} />);

                    }
                    else if (control instanceof Controls.NameAndTitleControl) {

                        const controlData: Controls.IControlData | undefined = control.data;
                        const textboxData: TextBoxData | undefined = controlData && TextBoxData.create((controlData as Controls.ITextData).text);

                        controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={page.page}
                            id={"document-control-" + control.id} isRequired={control.required}
                            key={"nameandtitle-" + control.id}
                            name={"NameAndTitle"}
                            placeholder={"Name and Title"} data={{ controlType: 4, controlId: control.id }}
                            helptext={control.tooltip} textboxData={textboxData}
                            top={top} left={left}
                            width={_self.getControlWidth(control)}
                            height={_self.getControlHeight(control)}
                            onChange={_self.setAllTextControlValueToSame}
                            disabled={control.disabled} />);
                    }
                    else if (control instanceof Controls.NameControl) {

                        const controlData: Controls.IControlData | undefined = control.data;
                        const textboxData: TextBoxData | undefined = controlData && TextBoxData.create((controlData as Controls.ITextData).text);

                        controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={page.page}
                            id={"document-control-" + control.id} isRequired={control.required}
                            key={"name-" + control.id}
                            name={"Name"}
                            placeholder={"Name"} data={{ controlType: 7, controlId: control.id }}
                            helptext={control.tooltip} textboxData={textboxData}
                            top={top} left={left}
                            width={_self.getControlWidth(control)}
                            height={_self.getControlHeight(control)}
                            onChange={_self.setAllTextControlValueToSame}
                            disabled={control.disabled} />);
                    }
                    else if (control instanceof Controls.TitleControl) {

                        const controlData: Controls.IControlData | undefined = control.data;
                        const textboxData: TextBoxData | undefined = controlData && TextBoxData.create((controlData as Controls.ITextData).text);

                        controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={page.page}
                            id={"document-control-" + control.id} isRequired={control.required}
                            key={"title-" + control.id}
                            name={"Title"}
                            placeholder={"Title"} data={{ controlType: 8, controlId: control.id }}
                            helptext={control.tooltip} textboxData={textboxData}
                            top={top} left={left}
                            width={_self.getControlWidth(control)}
                            height={_self.getControlHeight(control)}
                            onChange={_self.setAllTextControlValueToSame}
                            disabled={control.disabled} />);
                    }
                    else if (control instanceof Controls.CompanyControl) {

                        const controlData: Controls.IControlData | undefined = control.data;
                        const textboxData: TextBoxData | undefined = controlData && TextBoxData.create((controlData as Controls.ITextData).text);

                        controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={page.page}
                            id={"document-control-" + control.id}
                            key={"company-" + control.id}
                            name={"Company"} isRequired={control.required}
                            placeholder={"Company"} data={{ controlType: 5, controlId: control.id }}
                            helptext={control.tooltip} textboxData={textboxData}
                            top={top} left={left}
                            width={_self.getControlWidth(control)}
                            height={_self.getControlHeight(control)}
                            onChange={_self.setAllTextControlValueToSame}
                            disabled={control.disabled} />);
                    }
                    else if (control instanceof Controls.CustomTextControl) {

                        const controlData: Controls.IControlData | undefined = control.data;
                        const textboxData: TextBoxData | undefined = controlData && TextBoxData.create((controlData as Controls.ITextData).text);

                        controlCollection.push(<TextBoxControl maxLength={150} ref={(ref) => _self._controlList.push(ref)} page={page.page}
                            id={"document-control-" + control.id}
                            key={"custom-text-" + control.id}
                            name={"Text"} isRequired={control.required}
                            placeholder={control.tooltip} data={{ controlType: 6, controlId: control.id }}
                            helptext={control.tooltip} textboxData={textboxData}
                            top={top} left={left}
                            width={_self.getControlWidth(control)}
                            height={_self.getControlHeight(control)}
                            onChange={_self.setAllCustomTextControlValue}
                            type={TextBoxTypes.TextArea}
                            disabled={control.disabled} />);
                    }
                    else if (control instanceof Controls.CheckBoxControl) {

                        const controlData: Controls.IControlData | undefined = control.data;
                        const checkBoxData: CheckBoxData | undefined = controlData && CheckBoxData.create((controlData as Controls.ICheckBoxData).checked);

                        controlCollection.push(<CheckBoxControl ref={(ref) => _self._controlList.push(ref)} page={page.page}
                            id={"document-control-" + control.id}
                            key={"checkbox-" + control.id}
                            name={"CheckBox"}
                            data={{ controlType: 9, controlId: control.id }}
                            checkBoxData={checkBoxData == undefined ? CheckBoxData.createNullObject() : checkBoxData}
                            helptext={control.tooltip}
                            top={top} left={left}
                            width={_self.getControlWidth(control)}
                            height={_self.getControlHeight(control)}
                            onChange={_self.setAllCheckBoxControlValue}
                            draggable={false}
                            disabled={control.disabled}
                            isRequired={control.required} />);
                    }
                    else if (control instanceof Controls.PrintNameControl) {

                        const controlData: Controls.IControlData | undefined = control.data;
                        const textboxData: TextBoxData | undefined = controlData && TextBoxData.create((controlData as Controls.ITextData).text);

                        controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={page.page}
                            id={"document-control-" + control.id} isRequired={control.required}
                            key={"printName-" + control.id}
                            name={"PrintName"}
                            placeholder={"Print Name"} data={{ controlType: 10, controlId: control.id }}
                            helptext={control.tooltip} textboxData={textboxData}
                            top={top} left={left}
                            width={_self.getControlWidth(control)}
                            height={_self.getControlHeight(control)}
                            onChange={_self.setAllTextControlValueToSame}
                            disabled={control.disabled} />);
                    }

                    else if (control instanceof Controls.RadioControl) {
                        let radioButtons: RadioButton[] = [];

                        const minTop = Math.min.apply(Math, control.items.map(function (control) { return control.top; }));
                        const minLeft = Math.min.apply(Math, control.items.map(function (control) { return control.left; }));

                        control.items.forEach((item: RadioButton, index: number) => {
                            const tmpRadioButtonData = RadioButtonData.create(item.value?.checked, "");
                            const tmpRadioButton: RadioButton = RadioButton.create(item.name,
                                item.top - minTop,
                                item.left - minLeft,
                                item.width,
                                item.height,
                                tmpRadioButtonData);

                            tmpRadioButton.id = item.id;

                            radioButtons.push(tmpRadioButton);
                        });
                        
                        radioCount++;
                        const firstRadioBox = control.items.find(x => x.top == minTop);

                        controlCollection.push(<RadioButtonControl
                            id={"document-control-" + control.id}
                            key={"radioButton-" + control.id}
                            ref={(ref) => { _self._controlList.push(ref) }}
                            page={page.page}
                            data={{ controlType: 11, controlId: control.id }}
                            isRequired={control.required}
                            disabled={control.disabled}
                            width={(firstRadioBox && firstRadioBox.left ? firstRadioBox.left : 0) + 20 - minLeft}
                            height={15}
                            left={left + minLeft}
                            top={top + minTop - Radio_Button_Offset_Top}
                            helptext={control.tooltip}
                            name={"Options"}
                            items={radioButtons}
                            onChange={_self.setAllRadioControlValue} />);

                    }
                    else {

                    }

                };
            }

        });

        // return the control collection for checking if anyconditional control required is visible and its hidden so removing its validation
        this.removingConditionalControlValidation(conditionalControlsList);

        return controlCollection;

    }

    public render() {

        let navigationFinishTarget: any = undefined;

        if (this.props.finishTarget) {
            navigationFinishTarget = {
                targetId: this.props.finishTarget,
                text: "Finish",
            };
        }

        return (<PdfViewer>

            <Header>
                <Toolbar
                    ref={(ref: any) => this._toolbar = ref}
                    showDownload={true}
                    onDownload={this.props.downloadInitialDocuments}>
                    <Pagination />
                    <Zoom>
                    </Zoom>
                    <OtherOptions onViewModeChange={this.handleViewModeChange} />
                </Toolbar>
            </Header>
            {this.props.progress > 0 && <Progress status={this.props.progress} />}
            <Main>

                <LeftPanel>
                    {this.props.fileList}
                </LeftPanel>

                <ControlLayer ref={(ref: any) => this._controlLayer = ref}
                    useDefaultNavigationStartControl={this.props.AssignToDelegateeSigner ? false : !this.state.hideStartNavigationControl}
                    onNavigationComplete={this.onNavigationComplete}
                    navigationFinishTarget={navigationFinishTarget}
                    onNavigateNext={this.onNavigationNext}
                    onNavigationBegin={() => { this.setState({ hideStartNavigationControl: true }) }}>

                    {this.createControls()}

                </ControlLayer>

                <ViewPanel onDocumentLoad={this.startNavigation}
                    ref={(ref: any) => this._viewPanel = ref}
                    pdfSource={PdfSource.createFromUrl(this.props.url)} >

                </ViewPanel>

            </Main>
        </PdfViewer>);

    }
}
